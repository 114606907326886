import React from "react"
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"

import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
      <div className="text-center">
        <h1 className="text-4xl lg:text-5xl xl:text-6xl leading-none font-semibold">
          Oops! Wir konnten leider nicht finden, &nbsp;
          <br className="xl:hidden" />
          <span className="text-primary">wonach du suchst.</span>
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          <a href="/"> Schnell zurück zur Startseite 🌪</a>
        </p>
      </div>
    </div>
  </Layout>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
